import { useAuth0 } from '@auth0/auth0-react';
import { useSearchParams } from '@remix-run/react';
import { useEffect } from 'react';

export function useAuthentication() {
  const { isLoading, isAuthenticated, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    async function initializeAuthentication() {
      if (isAuthenticated) {
        try {
          await getAccessTokenSilently();
        } catch (_err) {
          await loginWithRedirect();
        }
      }

      if (!isAuthenticated && !isLoading && searchParams.get('invitation')) {
        const authorizationParams = {
          invitation: searchParams.get('invitation') as string,
          organization: searchParams.get('organization') as string,
        };

        try {
          await loginWithRedirect({
            authorizationParams,
          });
        } catch (err) {
          console.error(err);
        }
        return;
      }

      if (!isAuthenticated && !isLoading) {
        try {
          await loginWithRedirect();
        } catch (err) {
          console.error(err);
        }
      }
    }
    initializeAuthentication();
  }, [isAuthenticated, isLoading, searchParams, loginWithRedirect, getAccessTokenSilently]);

  return { isAuthenticated, isLoading };
}
