import { useAtomValue } from 'jotai';
import { useScript } from 'usehooks-ts';
import { userConfig } from '~/global-state/userConfig';
import { useAuthentication } from '~/hooks';

declare global {
  interface Window {
    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
    zE?: any;
  }
}

export function useZendeskChat() {
  const zE = window.zE;
  const { isEndConsumer } = useAtomValue(userConfig);
  const { isAuthenticated } = useAuthentication();

  function loadWidget() {
    // prevents load of Zendesk widget entirely when:
    // - user is on localhost
    // - user is not authenticated
    // - user is an end consumer
    const shouldPreventLoad = window.location.hostname === 'localhost' || !isAuthenticated || isEndConsumer;

    useScript('https://static.zdassets.com/ekr/snippet.js?key=6043c43e-f1b9-4277-8d17-c9ae728bad63', {
      id: 'ze-snippet',
      shouldPreventLoad,
    });
  }

  function toggleChat() {
    if (zE) {
      zE('messenger', 'show');
      zE('messenger', 'open');
    }
  }

  return { loadWidget, toggleChat };
}
